<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col v-if="loading"></v-col>
      <v-col v-else cols="12">
        <v-card class="" max-width="">
          <v-row>
            <v-col class="col-12 col-md-12 client_info">
              <v-row class="">
                <v-col class="col-md-3 col-12">
                  <v-avatar
                    class="profile"
                    color="grey"
                    size="164"
                    tile
                    v-if="!$vuetify.breakpoint.mobile"
                  >
                    <v-img :src="avatar"></v-img>
                  </v-avatar>
                  <v-col
                    v-if="client.is_banned && $auth.check('superadmin')"
                    class="red--text"
                  >
                    <!--                    <v-btn color="error" depressed>Забанен</v-btn>-->
                    <v-spacer></v-spacer>
                    <v-btn color="green" @click="ban_client(false)"
                      >Разбанить</v-btn
                    >
                  </v-col>
                  <v-col v-if="!client.is_banned && $auth.check('superadmin')">
                    <v-btn color="red" @click="ban_client(true)"
                      >Забанить</v-btn
                    >
                  </v-col>
                </v-col>
                <v-col class="col-md-9 col-12">
                  <v-container fluid>
                    <v-row>
                      <v-col
                        ><h2 class="red--text" v-if="client.is_banned">
                          Клиент заблокирован
                        </h2></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="font-weight-black"
                        >Имя: {{ full_name }}</v-col
                      >
                      <v-col cols="6" class="font-weight-black"
                        >Телефон: {{ phone }}</v-col
                      >
                      <v-col cols="6">
                        <v-badge
                          :color="badge_color"
                          :content="email_confirmed"
                          v-if="client.email"
                        >
                          Email: {{ client.email }}
                        </v-badge>
                        <span v-else>Email: Не установлен</span>
                      </v-col>
                      <v-col cols="6"
                        >Регистрация: {{ client.created_at }}</v-col
                      >
                      <v-col cols="6"
                        >Последняя активность:
                        {{ client.current_login_at }}</v-col
                      >
                      <v-col cols="6"
                        >Последний IP: {{ client.current_login_ip }}</v-col
                      >
                      <v-col cols="6"
                        >Завершение на 80%:
                        {{ client.ending_at_80 ? "Да" : "Нет" }}
                        <v-btn
                          @click="change_ending"
                          v-if="$auth.check('superadmin')"
                          >Изменить</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="client.comment"
                    :readonly="!edit_comment"
                    label="Комментарий к клиенту"
                  ></v-textarea>
                </v-col>
                <v-col>
                  <v-btn v-if="!edit_comment" @click="edit_comment = true"
                    >Редактировать</v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn
                    v-if="edit_comment"
                    color="primary"
                    @click="save_comment"
                    >Сохранить</v-btn
                  >
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn
                    v-if="edit_comment"
                    color="error"
                    @click="edit_comment = false"
                    >Отменить</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col class="col-12">
              <v-card elevation="0">
                <v-card-title> Карты клиента </v-card-title>
                <v-data-table
                  :items="client.cards"
                  :headers="card_header"
                  no-results-text="Карты не найдены"
                  no-data-text="Нет подключенных карт"
                  hide-default-footer
                >
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="ban_card(item)"
                          v-if="$auth.check('superadmin')"
                          class="text-decoration-none"
                          :color="ban_btn_color(item)"
                          ><v-icon>mdi-cancel</v-icon></v-btn
                        >
                      </template>
                      <span v-if="!item.banned">Заблокировать карту</span>
                      <span v-else>Разблокировать карту</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col class="col-12 col-md-12 client_card">
              <v-card elevation="0">
                <v-card-title>История зарядок</v-card-title>
                <v-data-table
                  :items="client_history"
                  :headers="headers"
                  :items-per-page="25"
                  no-results-text="Сессии не найдены"
                  no-data-text="Сессии не найдены"
                  :footer-props="{
                    'items-per-page-options': [10, 25, 50, 100, -1],
                  }"
                >
                  <template v-slot:item.status="{ item }">
                    {{ charging_status(item.status) }}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                      color="primary"
                      :to="{
                        name: 'charging_info',
                        params: { charge_id: item.id },
                      }"
                      >Подробнее
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ClientInfo",
  created() {
    this.$store.dispatch("client_info", this.$route.params.client_id);
  },
  data: () => {
    return {
      edit_comment: false,
      headers: [
        {
          text: "Дата",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Статус",
          align: "start",
          sortable: true,
          value: "status",
          width: "15%",
        },
        {
          text: "Передано энергии",
          align: "start",
          sortable: true,
          value: "power_amount",
        },
        {
          text: "Оплата",
          align: "start",
          sortable: true,
          value: "payment_amount",
        },
        {
          text: "",
          value: "action",
        },
      ],
      card_header: [
        {
          text: "Идентификатор",
          align: "start",
          sortable: true,
          value: "card_id",
        },
        {
          text: "Маска карты",
          align: "start",
          sortable: true,
          value: "card_mask",
        },
        {
          text: "Месяц окончания",
          align: "start",
          sortable: true,
          value: "expiration_date_month",
        },
        {
          text: "Год окончания",
          align: "start",
          sortable: true,
          value: "expiration_date_year",
        },
        {
          text: "Основная",
          align: "start",
          sortable: true,
          value: "active",
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.getters.CLIENT_LOADING;
    },
    client() {
      return this.$store.getters.CLIENT_INFO;
    },
    avatar() {
      if (this.$store.getters.CLIENT_INFO.avatar != null) {
        return "https://ruscharge.ru/" + this.$store.getters.CLIENT_INFO.avatar;
      }
      return "";
    },
    phone() {
      if (this.client.phone != null) {
        return `+${this.client.phone}`;
      }
      return "";
    },
    full_name() {
      let surname = "",
        name = "";
      if (this.client.surname != null) {
        surname = this.client.surname;
      }
      if (this.client.name != null) {
        name = this.client.name;
      }
      return surname + " " + name;
    },
    client_history() {
      return this.client.history;
    },
    tester_btn_text() {
      if (this.client.is_tester) {
        return "Удалить тестера";
      }
      return "Сделать тестером";
    },
    email_confirmed() {
      if (this.client.email_confirmed) {
        return "Подтвержден";
      }
      return "Не подтвержден";
    },
    badge_color() {
      if (this.client.email_confirmed) {
        return "green";
      }
      return "error";
    },
  },
  methods: {
    charging_status(status) {
      switch (status) {
        case "preparing":
          return "Подготовка";
        case "charging":
          return "Зарядка";
        case "end":
          return "Зарядка завершена";
        case "paid":
          return "Зарядка завершена, оплачена";
      }
    },
    status_color(status) {
      console.log(status);
      return "red";
      // switch (status) {
      //   case "end":
      //     return "red";
      //   case "paid":
      //     return "green";
      // }
    },
    update_tester() {
      return this.$store
        .dispatch("update_tester", {
          client: this.$route.params.client_id,
          is_tester: !this.client.is_tester,
        })
        .then(() => {
          this.$store.dispatch("client_info", this.$route.params.client_id);
        });
    },
    change_ending() {
      return this.$store
        .dispatch("update_ending", {
          client: this.$route.params.client_id,
          ending_80: !this.client.ending_at_80,
        })
        .then(() => {
          this.$store.dispatch("client_info", this.$route.params.client_id);
        });
    },
    ban_client(ban_status) {
      return this.$store
        .dispatch("update_ban", {
          client: this.$route.params.client_id,
          ban: ban_status,
        })
        .then(() => {
          this.$store.dispatch("client_info", this.$route.params.client_id);
        });
    },
    ban_card(card) {
      return this.$store
        .dispatch("card_ban", {
          card: card.card_id,
          ban: !card.banned,
        })
        .then(() => {
          this.$store.dispatch("client_info", this.$route.params.client_id);
        });
    },
    ban_btn_color(item) {
      if (item.banned) {
        return "green";
      }
      return "red";
    },
    save_comment() {
      return this.$store
        .dispatch("save_comment", {
          comment: this.client.comment,
          client_id: this.$route.params.client_id,
        })
        .then(() => {
          this.edit_comment = false;
          this.$store.dispatch("client_info", this.$route.params.client_id);
        });
    },
  },
};
</script>

<style scoped></style>
